import styles from "./Footer.module.css";
import { Col, Container, Row } from "./Grid";

export const Footer = (props) => {
  return (
    <footer className={`${styles.footer} text-center`}>
      <Container>
        <Row>
          <Col className="col text-center">
            <p className="mb-0">Copyright © 2023</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
