import { Container, Row, Col } from "./Grid";

export const ProjectItem = (props) => {
  return (
    <Container className="project mt-5">
      <Row className="project__case-study-item mb-5">
        <Col className="col-md-12 order-sm-12">
          <h2 className="title dark-gray mb-4 mt-1"> {props.projectTitle} </h2>
          <img
            src={props.imageSrc}
            className="project__image img-fluid mb-2 mt-2"
            alt={props.imageAlt}
          />

          <small className="dark-gray">
            <i>Note: This sample is used for demo purposes only. </i>
          </small>
          {/* <h6 className="mt-3 bold"> Description: </h6>
            <p>{props.projectDescription}</p> */}
          <h3 className="sub-title bold mt-3"> Involved in : </h3>
          <p>{props.conceptsImplemented}</p>
          <span className="tag-container">
            {props.tags.map((item) => {
              return <small className="tag mr-2"> {item} </small>;
            })}
          </span>
        </Col>
      </Row>
      <hr />
    </Container>
  );
};
