import { Col, Container, Row } from "../components/Grid";
import dev from "../assets/images/dev.svg";
import { Companies } from "../components/Companies";
import { ProjectItem } from "../components/ProjectItem";
import bbcCalendar from "../assets/images/bbc_calendar.webp";
import bbcAcademy from "../assets/images/bbc_academy.webp";
import gloji from "../assets/images/gloji.webp";
import ndpp from "../assets/images/ndpp.webp";

export const Home = (props) => {
  return (
    <>
      <Container className="hero">
        <Row>
          <Col className="col-md-5 col-sm-12 hero__text-container home dark-gray">
            <div>
              <h1 class="dark-gray title">Senior React/UX/UI Developer</h1>
              <hr />
              <h2 class="dark-gray tagline">
                I help build&nbsp;
                <span className="gradient1">great products&nbsp;</span>
                with awesome&nbsp;
                <span className="gradient1">user experiences.</span>
              </h2>
            </div>
          </Col>
          <Col className="col-md-7 col-sm-12">
            <img src={dev} alt="Hero" className="hero__image" />
          </Col>
        </Row>
      </Container>
      <div className="gradient-sec text-center">
        <Container>
          <Row>
            <Col className="col">
              <h2>
                <i>Passion, Creativity and Technology </i>
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      <Companies />
      <Container>
        <Row>
          <Col className="col text-center mt-5">
            <h2 className="gradient1">Recent Projects</h2>
          </Col>
        </Row>
      </Container>

      <ProjectItem
        projectTitle="NHS DPP"
        imageSrc={ndpp}
        imageAlt="ndpp"
        conceptsImplemented="End to end UI Development with React"
        tags={["Development", "ReactJS"]}
      />

      <ProjectItem
        projectTitle="Gloji"
        imageSrc={gloji}
        imageAlt="gloji"
        conceptsImplemented="End to end UI Development with React"
        tags={["Development", "ReactJS"]}
      />

      <ProjectItem
        projectTitle="BBC Calendar"
        imageSrc={bbcCalendar}
        imageAlt="bbc calendar"
        conceptsImplemented="API Design, UX/UI, Front-end Architecture, App Development with React + GraphQL"
        tags={["Development", "UX/UI", "ReactJS"]}
      />
      <ProjectItem
        projectTitle="BBC Academy"
        imageSrc={bbcAcademy}
        imageAlt="bbc calendar"
        conceptsImplemented="Fullstack Development with NodeJS + Express + ReactJS "
        tags={["Development", "NodeJS", "ReactJS"]}
      />
      <Container className="contactSection">
        <Row>
          <Col className="col text-center">
            <p>
              Get in touch :&nbsp;
              <a href="mailto:anvesh021@gmail.com">anvesh021@gmail.com</a>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};
