import React from "react";
import styled from "styled-components";

export const Container = (props) => {
  return (
    <div className={`container ${props.className ? props.className : ""}`}>
      {props.children}
    </div>
  );
};

export const Row = (props) => {
  return (
    <div className={`row ${props.className ? props.className : ""}`}>
      {props.children}
    </div>
  );
};

export const Col = (props) => {
  return <div className={props.className}>{props.children}</div>;
};

export const FlexContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const FlexContainerCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto auto;
  background-color: white;
`;

export const ContainerFull = styled.div`
  width: 100%;
`;
