import "./App.scss";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Link, Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { About, Home } from "./pages";
import { Container, Row, Col } from "./components/Grid";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <main className="page home">
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            {/* <Route path="/about" exact>
              <About />
            </Route> */}
          </Switch>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
