import { Col, Container, Row } from "./Grid";
import { Link } from "react-router-dom";

export const Header = (props) => {
  return (
    <>
      <header>
        <Container>
          <Row>
            <Col className="col">
              <nav className="navbar navbar-light">
                <h1 className="logo_text">
                  <Link to="/">
                    <span className="navbar-brand gradient1">
                      Anveshi Manne
                    </span>
                  </Link>
                </h1>
              </nav>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};
