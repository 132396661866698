import { Row, Col, Container } from "./Grid";
import bbcLogo from "../assets/images/bbc_logo.svg";
import nhsLogo from "../assets/images/nhs_logo.svg";
import hclLogo from "../assets/images/hcl_logo.svg";
import ihgLogo from "../assets/images/ihg_logo.svg";
import armLogo from "../assets/images/arm_logo.svg";
import accessPayyLogo from "../assets/images/accessPay_logo.svg";

export const Companies = (props) => {
  return (
    <>
      <Container className="high-light-sec clients text-center mb-4 mt-5">
        <Row>
          <Col className="col-md-12">
            <h2 className="text-center">Companies I worked with</h2>
            <br />
            <p>
              I was commissioned by different companies &amp; agencies to work
              on challenging and high stake projects.
            </p>
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col className="col-md-2 col-sm-2 col-4 logo-container">
            <img src={bbcLogo} alt="BBC logo" className="img-fluid" />
          </Col>
          <Col className="col-md-2 col-sm-2 col-4 logo-container">
            <img src={nhsLogo} alt="NHS logo" className="img-fluid" />
          </Col>
          <Col className="col-md-2 col-sm-2 col-4 logo-container">
            <img src={armLogo} alt="ARM logo" className="img-fluid" />
          </Col>
          <Col className="col-md-2 col-sm-2 col-4 logo-container r-2">
            <img src={ihgLogo} alt="IHG logo" className="img-fluid" />
          </Col>

          <Col className="col-md-2 col-sm-2 col-4 logo-container r-2">
            <img
              src={accessPayyLogo}
              alt="AccessPay Logo"
              className="img-fluid"
            />
          </Col>
          <Col className="col-md-2 col-sm-2 col-4 logo-container r-2">
            <img src={hclLogo} alt="hcl logo" className="img-fluid" />
          </Col>
        </Row>
      </Container>
      <hr />
    </>
  );
};
